import React, { useContext, useEffect, useState } from "react";
import LoadingAction from "../../../../themes/LoadingAction/LoadingAction";
import "./AdminEditPagamento.css";
import { Button, Checkbox, Col, Input, Row, Tag, Typography } from "antd";
import { AuthContext } from "../../../../contexts/AuthContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import * as links from "../../../../utils/links";
import axios from "axios";
import question_icon from "../../../../assets/images/question.png";
import { ArrowLeftOutlined, SaveOutlined } from "@ant-design/icons";

const AdminEditPagamento = (props) => {
  const location = useLocation();
  let navigate = useNavigate();

  const { maquinaInfos, clienteInfo } = location.state;

  const { authInfo, setNotiMessage } = useContext(AuthContext);

  const [data, setData] = useState({
    nome: maquinaInfos?.nomeMaquina ?? "",
    descricao: maquinaInfos?.loja ?? "",
    estoque: Number(maquinaInfos?.estoque) ?? 0,
    store_id: Number(maquinaInfos?.mercadoPagoId) ?? 0,
    maquininha_serial: String(maquinaInfos?.idPseg),
    valorDoPulso: maquinaInfos?.pulso ?? 0,
    bonusAtivo: maquinaInfos?.bonusAtivo ?? false,
    valorJogadaBonus: maquinaInfos?.valorJogadaBonus ?? 0,
    quantidadePulsosBonus: maquinaInfos?.quantidadePulsoBonus ?? 0,
    bonusAtivo2: maquinaInfos?.bonusAtivo2 ?? false,
    valorJogadaBonus2: maquinaInfos?.valorJogadaBonus2 ?? 0,
    quantidadePulsosBonus2: maquinaInfos?.quantidadePulsoBonus2 ?? 0,
    bonusAtivo3: maquinaInfos?.bonusAtivo3 ?? false,
    valorJogadaBonus3: maquinaInfos?.valorJogadaBonus3 ?? 0,
    quantidadePulsosBonus3: maquinaInfos?.quantidadePulsoBonus3 ?? 0,
    bonusAtivo4: maquinaInfos?.bonusAtivo4 ?? false,
    valorJogadaBonus4: maquinaInfos?.valorJogadaBonus4 ?? 0,
    quantidadePulsosBonus4: maquinaInfos?.quantidadePulsoBonus4 ?? 0,
  });
  const [errors, setErrors] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const token = authInfo?.dataUser?.token;

  const { id } = useParams();

  const handleChange = (name, value) => {
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => {
      let errorsTemp = { ...prev };
      delete errorsTemp[name];
      return errorsTemp;
    });
  };

  const onSave = () => {
    // check require
    let errorsTemp = {};
    if (data.nome.trim() === "") {
      errorsTemp.nome = "Este campo é obrigatório";
    }
    if (data.descricao.trim() === "") {
      errorsTemp.descricao = "Este campo é obrigatório";
    }

    if (data.valorDoPulso < 0) {
      errorsTemp.valorDoPulso = "Este campo é obrigatório";
    }
    if (data.estoque < 0) {
      errorsTemp.estoque = "Estoque é obrigatório";
    }
    if (Object.keys(errorsTemp).length > 0) {
      setErrors(errorsTemp);
      return;
    }

    setIsLoading(true);
    axios
      .put(
        `${process.env.REACT_APP_SERVIDOR}/maquina`,
        {
          id,
          nome: data.nome,
          descricao: data.descricao,
          estoque: Number(data.estoque),
          store_id: String(data.store_id),
          maquininha_serial: String(data.maquininha_serial),
          valorDoPulso: data.valorDoPulso,
          bonusAtivo: data.bonusAtivo,
          valorJogadaBonus: Number(data.valorJogadaBonus),
          quantidadePulsosBonus: Number(data.quantidadePulsosBonus),
          bonusAtivo2: data.bonusAtivo2,
          valorJogadaBonus2: Number(data.valorJogadaBonus2),
          quantidadePulsosBonus2: Number(data.quantidadePulsosBonus2),
          bonusAtivo3: data.bonusAtivo3,
          valorJogadaBonus3: Number(data.valorJogadaBonus3),
          quantidadePulsosBonus3: Number(data.quantidadePulsosBonus3),
          bonusAtivo4: data.bonusAtivo4,
          valorJogadaBonus4: Number(data.valorJogadaBonus4),
          quantidadePulsosBonus4: Number(data.quantidadePulsosBonus4),
        },
        {
          headers: {
            "x-access-token": token,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        navigate(`${links.CLIENTES_MAQUINAS}/${clienteInfo.key}`, {
          state: location.state.clienteInfo,
        });
      })
      .catch((err) => {
        setIsLoading(false);
        if ([401, 403].includes(err.response.status)) {
          setNotiMessage({
            type: "error",
            message:
              "A sua sessão expirou, para continuar faça login novamente.",
          });
        } else if (err.response.status === 400) {
          setNotiMessage({
            type: "error",
            message: `${err.response.data.error}`,
          });
          setErrors((prev) => ({
            ...prev,
            nome: "Já existe uma máquina com esse nome",
          }));
        } else {
          setNotiMessage({
            type: "error",
            message: "Um erro ocorreu",
          });
        }
      });
  };

  return (
    <div className="Admin_PagamentosSearch_container">
      <div className="PagamentosSearch_container">
        {isLoading && <LoadingAction />}
        <Row style={{ width: "100%", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <ArrowLeftOutlined
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <Typography style={{ fontSize: "24px", fontWeight: "700" }}>
              Editar máquina
            </Typography>
            <Tag
              color={maquinaInfos.status === "Inativa" ? "#FEF0F4" : "#EEFBF4"}
              style={{
                borderRadius: "8px",
                color:
                  maquinaInfos.status === "Inativa" ? "#D50B3E" : "#17663A",
              }}
            >
              {maquinaInfos.status}
            </Tag>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Button
              style={{
                backgroundColor: "transparent",
                borderRadius: "8px",
                border: "1px solid transparent",
                padding: "18px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#D50B3E",
              }}
              onClick={() => {
                navigate(`${links.CLIENTES_MAQUINAS_DELETE_FORNECEDOR}/${id}`, {
                  state: location.state,
                });
              }}
              disabled={isLoading}
            >
              Excluir máquina
            </Button>
            <Button
              style={{
                backgroundColor: "transparent",
                borderRadius: "8px",
                padding: "18px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancelar
            </Button>
            <Button
              style={{
                backgroundColor: "#26A95F",
                color: "white",
                borderRadius: "8px",
                padding: "18px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                if (!isLoading) onSave();
              }}
              disabled={isLoading}
            >
              Salvar
              <SaveOutlined />
            </Button>
          </div>
        </Row>
        <Col
          style={{
            width: "100%",
            padding: "20px",
            border: "1px solid #EBEBEF",
            borderRadius: "8px",
            marginTop: "20px",
          }}
        >
          <Row style={{ width: "100%", gap: "20px" }}>
            <Col style={{ width: "50%" }}>
              {" "}
              <label
                className="AddCliente_itemFieldLabel"
                htmlFor="nome"
                style={{ fontWeight: "700" }}
              >
                Nome da máquina
              </label>
              <Input
                placeholder={"Nome da máquina"}
                value={data.nome}
                id="nome"
                type="text"
                name="nome"
                autoComplete="nome"
                onChange={(event) => {
                  handleChange("nome", event.target.value);
                }}
                className={`${!!errors.nome ? "AddCliente_inputError" : ""}`}
                style={{ borderRadius: "8px" }}
              />
              {errors.nome && (
                <div className="Update_Pagamento_itemFieldError">
                  {errors.nome}
                </div>
              )}
            </Col>
            <Col style={{ flex: 1 }}>
              <label
                className="AddCliente_itemFieldLabel"
                htmlFor="mercadoPagoToken"
                style={{ fontWeight: "700" }}
              >
                Descrição
              </label>

              <Input
                placeholder={"Nome da loja"}
                value={data.descricao}
                id="descricao"
                type="text"
                name="nome"
                autoComplete="descricao"
                onChange={(event) => {
                  handleChange("descricao", event.target.value);
                }}
                className={`${
                  !!errors.descricao ? "Update_Pagamento_inputError" : ""
                }`}
                style={{ borderRadius: "8px" }}
              />
              {errors.descricao && (
                <div className="Update_Pagamento_itemFieldError">
                  {errors.descricao}
                </div>
              )}
            </Col>
          </Row>
          <Row style={{ width: "100%", gap: "20px", marginTop: "20px" }}>
            <Col style={{ width: "50%" }}>
              {" "}
              <label
                className="AddCliente_itemFieldLabel"
                htmlFor="nome"
                style={{ fontWeight: "700" }}
              >
                Store ID
              </label>
              <Input
                placeholder={"Nome da máquina"}
                value={data.store_id}
                id="store_id"
                type="text"
                name="store_id"
                autoComplete="store_id"
                onChange={(event) => {
                  handleChange("store_id", event.target.value);
                }}
                className={`${
                  !!errors.store_id ? "AddCliente_inputError" : ""
                }`}
                style={{ borderRadius: "8px" }}
              />
              {errors.store_id && (
                <div className="Update_Pagamento_itemFieldError">
                  {errors.store_id}
                </div>
              )}
            </Col>
            <Col style={{ flex: 1 }}>
              <label
                className="AddCliente_itemFieldLabel"
                htmlFor="mercadoPagoToken"
                style={{ fontWeight: "700" }}
              >
                Maquininha serial
              </label>

              <Input
                placeholder={"ID Pseg"}
                value={data.maquininha_serial}
                id="maquininha_serial"
                type="text"
                name="maquininha_serial"
                autoComplete="maquininha_serial"
                onChange={(event) => {
                  handleChange("maquininha_serial", event.target.value);
                }}
                className={`${
                  !!errors.maquininha_serial
                    ? "Update_Pagamento_inputError"
                    : ""
                }`}
                style={{ borderRadius: "8px" }}
              />
              {errors.maquininha_serial && (
                <div className="Update_Pagamento_itemFieldError">
                  {errors.maquininha_serial}
                </div>
              )}
            </Col>
          </Row>
          <Row style={{ width: "100%", gap: "20px", marginTop: "20px" }}>
            <Col style={{ width: "50%" }}>
              {" "}
              <label
                className="AddCliente_itemFieldLabel"
                htmlFor="nome"
                style={{ fontWeight: "700" }}
              >
                Estoque
              </label>
              <Input
                placeholder={"1.50"}
                value={data.estoque}
                id="estoque"
                type="number"
                name="estoque"
                autoComplete="estoque"
                onChange={(event) => {
                  handleChange("estoque", event.target.value);
                }}
                className={`${
                  !!errors.estoque ? "Update_Pagamento_inputError" : ""
                }`}
                style={{ borderRadius: "8px" }}
              />
              {errors.estoque && (
                <div className="Update_Pagamento_itemFieldError">
                  {errors.estoque}
                </div>
              )}
            </Col>
            <Col style={{ flex: 1 }}>
              <label
                className="AddCliente_itemFieldLabel"
                htmlFor="mercadoPagoToken"
                style={{ fontWeight: "700" }}
              >
                Valor do pulso
              </label>

              <Input
                placeholder={"1.50"}
                value={data.valorDoPulso}
                id="valorDoPulso"
                type="number"
                name="valorDoPulso"
                autoComplete="valorDoPulso"
                onChange={(event) => {
                  handleChange("valorDoPulso", event.target.value);
                }}
                className={`${
                  !!errors.valorDoPulso ? "Update_Pagamento_inputError" : ""
                }`}
                style={{ borderRadius: "8px" }}
              />
              {errors.valorDoPulso && (
                <div className="Update_Pagamento_itemFieldError">
                  {errors.valorDoPulso}
                </div>
              )}
            </Col>
          </Row>
          {/* Jogada Bônus 1 */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <Checkbox
              defaultChecked={data.bonusAtivo}
              value={data.bonusAtivo}
              onChange={() => {
                handleChange("bonusAtivo", !data.bonusAtivo);
              }}
            ></Checkbox>
            <p style={{ marginTop: "16px", fontWeight: 700 }}>Jogada Bônus 1</p>
          </div>

          <Row style={{ width: "100%", gap: "20px", marginTop: "20px" }}>
            <Col style={{ width: "50%" }}>
              <label
                className="AddCliente_itemFieldLabel"
                htmlFor="valorJogadaBonus"
                style={{ fontWeight: "700" }}
              >
                Valor de critério
              </label>
              <Input
                placeholder={"1.50"}
                value={data.valorJogadaBonus}
                id="valorJogadaBonus"
                type="number"
                name="valorJogadaBonus"
                onChange={(event) => {
                  handleChange("valorJogadaBonus", event.target.value);
                }}
                style={{ borderRadius: "8px" }}
              />
            </Col>
            <Col style={{ flex: 1 }}>
              <label
                className="AddCliente_itemFieldLabel"
                htmlFor="quantidadePulsosBonus"
                style={{ fontWeight: "700" }}
              >
                Pulsos adicionais
              </label>
              <Input
                placeholder={"1.50"}
                value={data.quantidadePulsosBonus}
                id="quantidadePulsosBonus"
                type="number"
                name="quantidadePulsosBonus"
                onChange={(event) => {
                  handleChange("quantidadePulsosBonus", event.target.value);
                }}
                style={{ borderRadius: "8px" }}
              />
            </Col>
          </Row>

          {/* Jogada Bônus 2 */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <Checkbox
              defaultChecked={data.bonusAtivo2}
              value={data.bonusAtivo2}
              onChange={() => {
                handleChange("bonusAtivo2", !data.bonusAtivo2);
              }}
            ></Checkbox>
            <p style={{ marginTop: "16px", fontWeight: 700 }}>Jogada Bônus 2</p>
          </div>

          <Row style={{ width: "100%", gap: "20px", marginTop: "20px" }}>
            <Col style={{ width: "50%" }}>
              <label
                className="AddCliente_itemFieldLabel"
                htmlFor="valorJogadaBonus2"
                style={{ fontWeight: "700" }}
              >
                Valor de critério
              </label>
              <Input
                placeholder={"2.00"}
                value={data.valorJogadaBonus2}
                id="valorJogadaBonus2"
                type="number"
                name="valorJogadaBonus2"
                onChange={(event) => {
                  handleChange("valorJogadaBonus2", event.target.value);
                }}
                style={{ borderRadius: "8px" }}
              />
            </Col>
            <Col style={{ flex: 1 }}>
              <label
                className="AddCliente_itemFieldLabel"
                htmlFor="quantidadePulsosBonus2"
                style={{ fontWeight: "700" }}
              >
                Pulsos adicionais
              </label>
              <Input
                placeholder={"2.50"}
                value={data.quantidadePulsosBonus2}
                id="quantidadePulsosBonus2"
                type="number"
                name="quantidadePulsosBonus2"
                onChange={(event) => {
                  handleChange("quantidadePulsosBonus2", event.target.value);
                }}
                style={{ borderRadius: "8px" }}
              />
            </Col>
          </Row>

          {/* Jogada Bônus 3 */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <Checkbox
              defaultChecked={data.bonusAtivo3}
              value={data.bonusAtivo3}
              onChange={() => {
                handleChange("bonusAtivo3", !data.bonusAtivo3);
              }}
            ></Checkbox>
            <p style={{ marginTop: "16px", fontWeight: 700 }}>Jogada Bônus 3</p>
          </div>

          <Row style={{ width: "100%", gap: "20px", marginTop: "20px" }}>
            <Col style={{ width: "50%" }}>
              <label
                className="AddCliente_itemFieldLabel"
                htmlFor="valorJogadaBonus3"
                style={{ fontWeight: "700" }}
              >
                Valor de critério
              </label>
              <Input
                placeholder={"3.00"}
                value={data.valorJogadaBonus3}
                id="valorJogadaBonus3"
                type="number"
                name="valorJogadaBonus3"
                onChange={(event) => {
                  handleChange("valorJogadaBonus3", event.target.value);
                }}
                style={{ borderRadius: "8px" }}
              />
            </Col>
            <Col style={{ flex: 1 }}>
              <label
                className="AddCliente_itemFieldLabel"
                htmlFor="quantidadePulsosBonus3"
                style={{ fontWeight: "700" }}
              >
                Pulsos adicionais
              </label>
              <Input
                placeholder={"3.50"}
                value={data.quantidadePulsosBonus3}
                id="quantidadePulsosBonus3"
                type="number"
                name="quantidadePulsosBonus3"
                onChange={(event) => {
                  handleChange("quantidadePulsosBonus3", event.target.value);
                }}
                style={{ borderRadius: "8px" }}
              />
            </Col>
          </Row>

          {/* Jogada Bônus 4 */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <Checkbox
              defaultChecked={data.bonusAtivo4}
              value={data.bonusAtivo4}
              onChange={() => {
                handleChange("bonusAtivo4", !data.bonusAtivo4);
              }}
            ></Checkbox>
            <p style={{ marginTop: "16px", fontWeight: 700 }}>Jogada Bônus 4</p>
          </div>

          <Row style={{ width: "100%", gap: "20px", marginTop: "20px" }}>
            <Col style={{ width: "50%" }}>
              <label
                className="AddCliente_itemFieldLabel"
                htmlFor="valorJogadaBonus4"
                style={{ fontWeight: "700" }}
              >
                Valor de critério
              </label>
              <Input
                placeholder={"4.00"}
                value={data.valorJogadaBonus4}
                id="valorJogadaBonus4"
                type="number"
                name="valorJogadaBonus4"
                onChange={(event) => {
                  handleChange("valorJogadaBonus4", event.target.value);
                }}
                style={{ borderRadius: "8px" }}
              />
            </Col>
            <Col style={{ flex: 1 }}>
              <label
                className="AddCliente_itemFieldLabel"
                htmlFor="quantidadePulsosBonus4"
                style={{ fontWeight: "700" }}
              >
                Pulsos adicionais
              </label>
              <Input
                placeholder={"4.50"}
                value={data.quantidadePulsosBonus4}
                id="quantidadePulsosBonus4"
                type="number"
                name="quantidadePulsosBonus4"
                onChange={(event) => {
                  handleChange("quantidadePulsosBonus4", event.target.value);
                }}
                style={{ borderRadius: "8px" }}
              />
            </Col>
          </Row>
        </Col>
      </div>
      {isLoading && <LoadingAction />}
    </div>
  );
};

export default AdminEditPagamento;
